.map-view__container {
  position: relative;
}

.btn-search {
  background-color: #5d1809 !important;
  color: #fff !important;
}

.font-search {
  color: #000;
}

.map-view {
  width: 100vw;
  height: 100vh;
  z-index: 10;
}

.image-icon-community {
  // height: 35px !important;
  // width: 35px !important;
  border-style: outset;
  border-color: #00ff22;
  border-width: 3px;
  border-radius: 50%;
}

.image-icon-partner {
  // height: 35px !important;
  // width: 35px !important;
  border-style: outset;
  border-color: #ff32f4;
  border-width: 3px;
  border-radius: 50%;
}

.image-icon-government {
  // height: 35px !important;
  // width: 35px !important;
  border-style: outset;
  border-color: rgb(0, 140, 255);
  border-width: 3px;
  border-radius: 50%;
}

.image-icon-philosopher {
  // height: 35px !important;
  // width: 35px !important;
  border-style: outset;
  border-color: orange;
  border-width: 3px;
  border-radius: 50%;
}

.image-icon-International {
  // height: 35px !important;
  // width: 35px !important;
  border-style: outset;
  border-color: rgb(0, 140, 255);
  border-width: 3px;
  border-radius: 50%;
}
.image-icon-other {
  // height: 30px !important;
  // width: 30px !important;
  /* border-style: ridge; border-color: orange; border-width:4px; */
  border-radius: 50%;
  border: 3px solid;
  border-color: orange;
  /* margin-bottom: 20px; */
}

.minimap {
  width: 30vw;
  height: 50vh;
  z-index: 10;
}
.minimap2 {
  width: 83.5vw;
  height: 59vh;
  z-index: 5;
}

.minimap3 {
  width: 65vw;
  height: 85vh;
  z-index: 5;
}
.minimap2sub {
  width: 50vw;
  height: 70vh;
  z-index: 1;
}

.minimap2sub1 {
  width: 100%;
  height: 85vh;
  z-index: 5;
}

.minimap4 {
  width: 25vw;
  height: 50vh;
  z-index: 5;
}
.minimap5 {
  width: 150%;
  height: 100%;
  z-index: 10;
  margin-left: 262px;
  margin-top: 5 px;
  margin-bottom: 10px;
}
