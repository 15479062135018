.spinner {
  animation: rotator 1.4s linear infinite;
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

.path {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash 1.4s ease-in-out infinite,
    colors (1.4s * 4) ease-in-out infinite;
}

@keyframes colors {
  0% {
    stroke: #4285f4;
  }
  25% {
    stroke: #de3e35;
  }
  50% {
    stroke: #f7c223;
  }
  75% {
    stroke: #1b9a59;
  }
  100% {
    stroke: #4285f4;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 187/4;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
  }
}

.modal {
  z-index: 1250 !important;
}
.modal-backdrop {
  z-index: 1250 !important;
}

// .img-radius {
//   border-radius: 15px;
// }
.img-output {
  border-radius: 15px;
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.card-output {
  box-shadow: 14px 13px 0px -5px rgb(159 159 159 / 37%);
  transition: 0.3s;
  width: 100%;
  height: 320px;
  padding: 1.5rem;
  border-radius: 15px;
  margin: 1rem;
  background-color: antiquewhite;
  cursor: pointer;
}

// .title-output {
// }

.output-descreption {
  padding-top: 1rem;

  h6 {
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
.card-output:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.tree,
.tree ul {
  list-style-type: none;

  margin-left: 0 0 0 10px;

  padding: 0;

  position: relative;

  overflow: hidden;
}

.tree li {
  margin: 0;

  padding: 0;

  position: relative;
}

.tree li::before,
.tree li::after {
  content: "";

  position: absolute;

  left: 0;
}

/* horizontal line on inner list items */

.tree li::before {
  border-top: 5px solid #882e1a;
  top: 50%;
  width: 35px;
  height: 0;
}

/* vertical line on list items */

.tree li:after {
  border-left: 5px solid #882e1a;

  height: 100%;

  width: 0px;

  top: -5px;
}

/* lower line on list items from the first level because they don't have parents */

.tree > li::after {
  top: 15px;
}

/* hide line from the last of the first level list items */

.tree > li:last-child::after {
  display: none;
}

/* hide line from before the Home or starting page or first element	*/

.tree > li:first-child::before {
  display: none;
}

.tree ul:last-child li:last-child:after {
  height: 52%;
}

.tree a:hover {
  color: red;

  font-weight: 500;

  text-shadow: 1px 2px 2px #f3f3f3;
}

.div-image-knowledge {
  display: flex;
  justify-content: center;
  align-items: center;
  // height: 450px;
}

.economy {
  background-color: #acd0e6 !important;
}
// .economy-table {
//   --bs-table-accent-bg: #5da4e5;
// }
.society {
  background-color: #f5a834 !important;
}
// .society-table {
//   --bs-table-accent-bg: #e5ae5d;
// }
.culture {
  background-color: #d7dd99 !important;
}
// .culture-table {
//   --bs-table-accent-bg: #76cc80;
// }
.environment {
  background-color: #acbce6 !important;
}
// .environment-table {
//   --bs-table-accent-bg: #c29de4;
// }

.text-table {
  margin-bottom: 7px;
}
.RMUTICLUSTER {
  background-color: #acd0e6 !important;
}
.SDG {
  background-color: #f5a834 !important;
}
.BCG {
  background-color: #d7dd99 !important;
}
.SCURVE {
  background-color: #acbce6 !important;
}

.table > :not(caption) > * > * {
  padding: 0.5rem 1.5rem;
}

.table {
  --bs-table-bg: rgba(255, 255, 255, 0);
  // --bs-table-accent-bg: #76cc80;
  --bs-table-striped-color: #212529;
  --bs-table-striped-bg: rgb(255 255 255 / 5%);
  --bs-table-active-color: #212529;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #212529;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  vertical-align: top;
  border-color: #8d8f91;
}

.nav-tabs .nav-link {
  margin: 0 10px 6px;
  height: 35px;
  color: #495057;
  background: #ff9200;
  border: 1px solid transparent;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transition: all 0.3s ease-in-out;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  height: 60px;
  border-radius: 10px;
  color: #495057;
  border: 2px solid #fff !important;
  box-shadow: 0px 0px 3px 1px #b09c9c;
}

.nav-tabs {
  border: none;
}
.tab-content {
  margin-top: -3rem;
}
.tab-table {
  background-color: #eeeeee;
  padding: 2rem;
  padding-top: 4rem;
  border-radius: 10px;
}
.card-goal {
  padding: 2rem;
  border-radius: 10px;
}

.card-title-goal {
  color: black;
  margin-bottom: 1rem;
  margin-top: 2rem;
  text-align: left;
}
.goal-detail {
  background-color: #fff;
  padding: 2rem;
  border-radius: 10px;
  margin-top: 1rem;
}

.goal-row-detail {
  background-color: antiquewhite;
  margin: 2rem 0 0 0;
  padding: 1rem 2rem;
  padding-bottom: 2rem;
  border-radius: 10px;
}

.goal-video {
  padding-bottom: 2rem;
}

.div-knowledge-detail {
  margin-left: 25px;
}

.card-knowledge-tree {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  padding: 2rem;
  border: none;
}

.knowledge-tree-detail {
  padding: 0px 2rem;
}
.knowledge-tree-outcome {
  color: rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);
  border: none;
  border-radius: 15px;
  padding: 2rem;
}

.newkn-div {
  padding: 2rem;
}
.newkn-detail {
  padding: 0px 2rem;
  text-align: left;
}

@media (max-width: 426px) {
  .card-goal {
    margin-left: -1rem;
    margin-right: -1rem;
    padding: 1rem 10px;
  }
  .goal-detail {
    padding: 2rem 0;
  }
  .goal-row-detail {
    padding: 1rem 10px;
  }
  .goal-video {
    padding-top: 2rem;
  }
  .div-knowledge-detail {
    margin-left: 0;
    margin-right: -23px;
  }
  .div-knowledge-tree {
    margin-left: -15px;
    margin-right: -15px;
  }
  .card-knowledge-tree {
    padding: 2rem 15px;
    padding-right: 0;
  }

  .knowledge-tree-detail {
    padding: 0 0;
  }

  .knowledge-tree-outcome {
    padding: 2rem 10px;
  }

  .newkn-detail {
    padding: 0;
  }
  .newkn-div {
    padding: 10px;
  }
  .accordion-body {
    padding: 1rem 0;
  }
  .dashboard .all-card {
    padding: 30px 0 !important;
  }
}
