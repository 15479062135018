@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css");

body {
  font-family: Prompt !important;
}
.MuiTypography-body1{
    font-family: Prompt !important;
}
.blog-card {
  display: flex;
  flex-direction: column;
  //   max-width: 1100px;
  margin: 0 auto;
  box-shadow: 0 3px 7px -1px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
  background: #fff;
  line-height: 1.45;
  font-family: Prompt;
  border-radius: 15px;
  overflow: hidden;
  z-index: 0;
  text-align: left;

  a {
    color: inherit;

    &:hover {
      color: #ffc400;
    }
  }

  &:hover .photo {
    -webkit-transform: scale(1.15) rotate(2deg);
    transform: scale(1.15) rotate(2deg);
  }

  .meta {
    position: relative;
    z-index: 0;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .photo {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-position: center;
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
  }

  .description {
    padding: 1rem;
    background: #fff;
    position: relative;
    z-index: 1;

    h1,
    h2 {
      font-family: Prompt;
    }

    h1 {
      line-height: 1.4;
      margin: 0;
      font-size: 1.7rem;
    }

    h2 {
      font-size: 1rem;
      font-weight: 300;
      text-transform: uppercase;
      color: #a2a2a2;
      margin-top: 5px;
    }

    p {
      position: relative;
      margin: 0.3rem 0 0;
      color: #555;
      font-size: 14px;

      &:first-of-type {
        margin-top: 1.25rem;

        &:before {
          content: "";
          position: absolute;
          height: 3px;
          background: #ffc400;
          width: 75px;
          top: -1rem;
          border-radius: 3px;
        }
      }
    }
  }

  &:hover .details {
    left: 0%;
  }
}

@media (min-width: 640px) {
  .blog-card {
    flex-direction: row;

    .meta {
      flex-basis: 40%;
      height: auto;
    }

    .description {
      flex-basis: 60%;

      &:before {
        -webkit-transform: skewX(-3deg);
        transform: skewX(-3deg);
        content: "";
        background: #fff;
        width: 30px;
        position: absolute;
        left: -10px;
        top: 0;
        bottom: 0;
        z-index: -1;
      }
    }

    &.alt {
      flex-direction: row-reverse;

      .description:before {
        left: inherit;
        right: -10px;
        -webkit-transform: skew(3deg);
        transform: skew(3deg);
      }

      .details {
        padding-left: 25px;
      }
    }

    p {
      font-size: 16px;
    }
  }
}
