.btn-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.page-btn {
  width: 2rem;
  height: 2rem;
  background: #c48d7b;
  border-color: transparent;
  border-radius: 50%;
  cursor: pointer;
  margin: 0.5rem;
  transition: var(--transition);
}
.active-btn {
  background: #c0775f;
  color: var(--clr-white);
}
.prev-btn,
.next-btn {
  background: transparent;
  border-color: transparent;
  font-weight: bold;
  text-transform: capitalize;
  letter-spacing: var(--spacing);
  margin: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
  color: rgb(100, 100, 100);
}
