/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.uptotop {
  position: fixed;
  // padding-left: 5px;
  // display: none;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 3px;
  right: 15px;
  bottom: 45px;
  background: #055f8e;
  color: #fff;
  transition: display 0.5s ease-in-out;
  z-index: 99999;
}
.bg-img-menubar {
  position: fixed;
  height: 73px;
  width: 225px !important;
  left: -13px;
  top: -3px;
}

.icon-menu {
  width: 35px;
  height: 5px;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  margin: 6px 0;
}

.div-icon-menu {
  cursor: pointer;
  position: fixed;
  top: 14px;
  left: 12px;
}
.icon-menu-bar {
  font-size: xx-large;
  cursor: pointer;
  color: #fff;
}
.menubar2 {
  height: 70px;
  background-color: #528ac0;
  position: fixed;
  left: 79px;
  border-radius: 0px 0px 0px 60px;
  box-shadow: rgb(150 147 147 / 72%) 0px 2px 5px 0px;
}
.img-menubar2 {
  position: fixed;
  height: 70px;
  width: 225px !important;
  left: 40px;
}

.bg-title-th {
  height: 21px;
  width: 220px !important;
  top: 5px;
  background: linear-gradient(91deg, #f7c043 0%, #f19c48 45%, #ed824b 90%);
  position: fixed;
  left: 140px;
  border-radius: 60px 0px 0px 60px;
  box-shadow: rgb(115 115 115) 4px 3px 0px 0px;
}

.bg-title-en {
  height: 21px;
  width: 330px !important;
  top: 32px;
  background: linear-gradient(91deg, #f7c043 0%, #f19c48 45%, #ed824b 90%);
  position: fixed;
  left: 158px;
  border-radius: 60px 0px 0px 60px;
  box-shadow: rgb(115 115 115) 4px 4px 0px 0px;
}

.top-bar {
  display: none;
  width: 100vw;
  position: fixed;
  background: linear-gradient(330deg, #f9b900 0%, #ec9b0f 45%, #f48702 90%);
  box-shadow: rgb(150 147 147 / 72%) 0px 0px 20px 0px;
  height: 50px;
  top: 0;
  z-index: 1190;
  flex: content;
}
.leftbar {
  display: block;
}

.body-detail {
  margin: 2rem;
  margin-left: 270px;
}

.footer-detail {
  margin-left: 240px;
}

.footer-bg {
  padding: 0 1rem;
  width: 100%;
  background-color: rgb(255, 255, 255);
  color: #000000;
}

.col-universitys {
  padding: 0 1rem 0 3rem;
}
.body-universitys {
  padding: 10px 25px 0 25px;
}
.img-profile-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.body-coresearcher {
  background-color: #fff;
  width: 100%;
  border-radius: 15px;
  // backgroundColor: "#fff",
  // width: "100%",
  // borderRadius: "15px 15px 15px 15px",
}
.div-expertise {
  background-color: #d5cbcb;
  border-radius: 0 0 10px 10px;
}
.body-expertise {
  background-color: #fff;
  padding: 2rem;
  padding-bottom: 1rem;
  margin-top: 0;
  margin-bottom: 2rem;
  border-radius: 10px;
  // padding: "2rem",
  // paddingBottom: "1rem",
  // marginTop: "0",
  // marginBottom: "1rem",
  // borderRadius: "10px",
}

.expertise-detail {
  padding: 2rem;
  background-color: #5d1809;
  width: 100%;
  margin-top: 0rem;
  border-radius: 0px 0px 15px 15px;
}
.line-researcher {
  display: none;
}
.line-expertise {
  display: none;
}

.card-search {
  background-color: #ffffff !important;
  border-radius: 15px !important;
  box-shadow: none !important;
  border: none !important;
  margin: 1rem 0 !important;
}
.col-project {
  padding: 0 1rem 0 3rem;
  // padding: "0 1rem 0 3rem"
}

.col-project-detail {
  padding: 10px 0 0 10px !important ;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown_menu-6 {
  animation: growDown 300ms ease-in-out forwards;
  transform-origin: top center;
}

.row-innovation {
  margin-left: 0px;
  margin-bottom: 30px;
  padding-top: 10px;
  width: 100% !important;
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }
  80% {
    transform: scaleY(1.1);
  }
  100% {
    transform: scaleY(1);
  }
}

@media only screen and (max-width: 1240px) {
  .leftbar {
    display: none;
  }
  .top-bar {
    display: block;
  }
  .body-detail {
    margin: 2rem;
    margin-top: 6rem;
  }
  .footer-detail {
    margin-left: 0;
  }
}

/* Medium Device = 1200px */

@media only screen and (min-width: 992px) and (max-width: 1199px) {
}

/* Tablet Device = 768px */

@media only screen and (min-width: 770px) and (max-width: 991px) {
}

/* Wide Mobile = 480px */

@media only screen and (max-width: 1024px) {
  .img-profile-header {
    justify-content: center !important;
  }
  .profile-image {
    max-height: 190px !important;
  }
}

@media only screen and (max-width: 769px) {
  .body-detail {
    margin: 0rem;
    margin-top: 4rem;
    // margin-left: 10px;
    // margin-bottom: 30px;
  }
  .card-searcher {
    padding-top: 3rem !important;
    border-radius: 0 !important;
    margin-top: -1.5rem !important;
  }
  .line-expertise {
    display: block;
    margin: 3rem 1rem;
  }

  .body-coresearcher {
    margin-top: -1.5rem;
    padding-top: 2rem;
    border-radius: 0;
  }
  .expertise-detail {
    border-radius: 0;
  }
}

/* Small Device = 320px */
@media only screen and (max-width: 479px) {
  .card-header {
    height: auto;
  }
  .leaflet-top,
  .leaflet-bottom {
    display: none;
  }
  .bg-title {
    padding: 1rem 40px !important;
  }

  .body-universitys {
    padding: 1rem 0 !important;
  }

  .col-project-detail {
    padding-left: 15px !important;
  }

  .body-expertise {
    padding: 1rem !important;
  }

  .body-detail {
    margin: 0rem;
    margin-top: 60px;
    // margin-left: 10px;
    // margin-bottom: 30px;
  }

  .expertise-detail {
    padding: 2rem 0;
  }

  .re-expertise-detail {
    padding: 1rem 0 !important;
    padding-top: 0 !important;
  }

  .title-innovation {
    padding-bottom: 15px;
  }
}

@media only screen and (max-width: 426px) {
  .body-detail {
    margin: 0rem;
    margin-top: 80px;
    // margin-left: 10px;
    // margin-bottom: 30px;
  }

  .expertise-detail {
    padding: 2rem 0;
  }

  .img-researcher {
    margin-bottom: 1rem;
  }
  .line-researcher {
    display: block;
    margin: 0.5rem 2rem;
  }
}

@media only screen and (max-width: 376px) {
  .accordion-body {
    padding: 1rem 0rem !important;
  }
}
