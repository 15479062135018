.App {
  text-align: center;
}

.tab-list {
  border-bottom: 1px solid #ccc;
  padding-left: 0;
}

.tab-list-item {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
  cursor: pointer;
}

.tab-list-active {
  background-color: white;
  border: solid #ccc;
  border-width: 1px 1px 0 1px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #c56d08;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(71, 7, 247);
}

.content {
  background-color: rgb(255, 215, 106);

  box-sizing: border-box;
}

.button {
  size: 50px;
  border: rgb(21, 4, 173);
  color: rgb(10, 13, 236);
  padding: 5px 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.myButton {
  box-shadow: inset 0px 1px 0px 0px #fce2c1;
  background-color: #ffc477;
  border: 1px solid #ffffff;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 15px;
  font-weight: bold;
  padding: 6px 24px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #000000;
}
.myButton:hover {
  background-color: #0400ff;
  color: #060af5;
}
.myButton:active {
  position: relative;
  top: 1px;
}
.picposi {
  text-align: right;
}

.textposi {
  text-align: left;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.button {
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}
.button1 {
  background-color: #4caf50;
} /* Green */
.button2 {
  background-color: #008cba;
} /* Blue */
.button3 {
  background-color: #e00b04;
} /* red */
.button4 {
  background-color: #f37827;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.block-example {
  width: 35rem;
  height: 17rem;
  margin: 0.5rem;
  background-color: rgb(204, 204, 204);
  display: inline-block;
}
