.div-publication {
  // background-color: rgb(223, 138, 138);
  margin-top: 2rem;
  padding: 15px;
  border-radius: 10px;
  border: 2px solid antiquewhite;
  text-align: left;
}

.text-vertical-ceter {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.detail_publication {
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  padding: 2rem;
  padding-top: 3rem;
}

@media only screen and (max-width: 1200px) {
  .detail_publication {
    margin-top: 2rem;
  }
}
