.image-card {
    width: 100%;
    // height: 300px;
    object-fit: cover;
   
  }
  
  .gallery div {
    display: inline-block;
//   height: 144px;
//   margin: 5px;
//   width: 230px;
}
.gallery a {
    position: relative;
    display: block;
//   width: 230px;
}
.overlay {
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  cursor: zoom-in;
  display: block;
  font-size: 100px;
  height: 99%;
  line-height:calc(34px + 230px);
  top: 0;
  left: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  text-align: center;
  transition: all 0.3s ease 0s;
  width: 100%;
  z-index: 20;
}
.gallery a:hover .overlay {
  opacity: 0.8;
}
  
  #lightbox-img {
   
    height: 80vh;
    max-width: 90vw;
    object-fit: cover;
  }
  
  #lightbox {
    cursor: zoom-out;
    z-index: 2000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(12, 12, 12, 0.893);
    display: flex;
    align-items: center;
    justify-content:space-around;
  }
  

  .btnlightbox {
    background: rgba(0,0,0,0.2);
    cursor: pointer;
    z-index: 999;
    text-align: center;
    height: 100%;
    width: 10%;
    vertical-align: middle;
    transition: 0.3s width, 0.2s background;
    // border: 2px solid #ff9c11;
    // background-color: #ffffff;
    
    // font-size: 50px;
  }

  .btnlightbox:hover {
    background: rgba(0,0,0,0.8);
    width: 11%;
    transition: 0.3s width, 0.2s background;
  }
  .btnlightbox:active {
    width: 12%;
    transition: 0.1s width;
  }

  .prev {
    position: absolute; top: 0; left: 0;
  }
  .next {
    position: absolute; top: 0; right: 0;
  }

  .next::after {
    content: ">";
    font-size: 50px;
    font-family: monospace;
    color: #fff;
    position: absolute;     
    right: 40%; 
    top: 49%;
  }
  .prev::after {
    content: "<";
    font-size: 50px;
    font-family: monospace;
    color: #fff;
    position: absolute; 
    left: 40%; 
    top: 49%;
  }

