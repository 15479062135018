// font stuff
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,200,300,600,700,900);

.inno-notdata {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 24px;
}

.inno-main-title {
  background: #d5cbcb;
  border-radius: 10px;
}
.inno-title {
  padding: 10px 25px;
  background-color: #5d1809;
  border-radius: 10px;
  font-family: Prompt;
  text-align: left;
  color: #fff;
}
.inno-image {
  width: 100%;
  height: 100%;
  padding: 20px 25px;
  text-align: left;
  background: rgb(255, 255, 255);
  border: 3px solid #5d1809;
  border-radius: 10px;
}

.inno-video {
  width: 100%;
  height: 400px;
  margin-top: 1rem;
  padding: 20px 25px;
  text-align: left;
  background: rgb(255, 255, 255);
  border: 3px solid #5d1809;
  border-radius: 10px;
}
.inno-contact {
  width: 100%;
  margin-top: 1rem;
  text-align: left;
  padding: 1rem;
  color: rgb(0, 0, 0);
  background: rgb(255, 255, 255);
  border: 3px solid #5d1809;
  border-radius: 10px;
}
.inno-other {
  background-color: #5d1809;
  margin-top: 2rem;
  padding: 2rem;
  border-radius: 10px;
}
.inno-other-title {
  color: rgb(255, 255, 255);
  font-family: Prompt;
  text-align: left;
}

.page-link {
  padding: 0.375rem 0.5rem !important;
}
.image-gallery-slide .image-gallery-image {
  height: 430px !important;
}
a.btn-innovation {
  background: #5d1809;
  border-radius: 4px;
  box-shadow: 0 2px 0px 0 rgba(0, 0, 0, 0.25);
  color: #ffffff;
  display: inline-block;
  padding: 6px 30px 8px;
  position: relative;
  text-decoration: none;
  transition: all 0.1s 0s ease-out;
}
div.card-bg-innovation {
  width: 100%;
  text-align: center;
}

.title-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  // border: 3px solid green;
}
.bg-title {
  padding: 35px 25px;
  background: #d5cbcb;
  border-radius: 20px;
}

.bg-filter {
  width: 100%;
  height: 100%;
  padding: 1.2rem 0.5rem 2rem 2rem;
  background: #5d1809;
  border-radius: 20px;
}
div.card-bg-innovation {
  background: #ffffff;
  display: flex;
  margin: 8px;
  position: relative;
  text-align: left;
  transition: all 0.3s 0s ease-in;
  width: 100%;
  height: 300px;
  z-index: 1;
  border-radius: 15px;

  .img-innovation {
    width: 100%;
    object-fit: cover;
    height: 100%;
    border-radius: 15px;
  }

  .card__image-holder {
    background: rgba(255, 255, 255, 0.1);
    height: 80%;
    padding-bottom: 0;
    border-radius: 20px;
  }

  div.card-innovation-title {
    background: #ffffff;
    padding: 6px 15px 10px;
    position: relative;
    height: 20%;
    z-index: 0;
    border-radius: 15px;

    small.innovation {
      display: block;
      font-size: 12px;
      // font-weight: 600;
      letter-spacing: -0.025em;
    }
    .innovation-title {
      font-size: 14px;
      //   font-weight: 700;
      letter-spacing: -0.05em;
      margin: 0;
      padding: 0;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  div.card-description {
    padding: 0 15px 10px;
    position: relative;
    font-size: 14px;
  }

  div.card-actions {
    box-shadow: 0 2px 0px 0 rgba(0, 0, 0, 0.075);
    padding: 10px 15px 20px;
    text-align: center;
  }
}

.hit-the-floor {
  color: #fff;
  // font-size: 12em;
  font-weight: bold;
  font-family: Helvetica;
  text-shadow: 0 1px 0 #ccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9,
    0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.1),
    0 1px 3px rgba(0, 0, 0, 0.3), 0 3px 5px rgba(0, 0, 0, 0.2),
    0 5px 10px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.2),
    0 20px 20px rgba(0, 0, 0, 0.15);
}

.text-shadow {
  color: #e0e6e9;
  position: relative;

  &:before {
    bottom: 2px;
    color: rgba(0, 0, 0, 0.1);
    content: attr(title);
    left: 9px;
    position: absolute;
    text-shadow: none;
    transform-origin: bottom left;
    transform: skew(20deg) scale(1, 0.95);
    z-index: -1;
  }
}

.react-multi-carousel-item {
  margin: 0 10px;
}

.card-innovation-all {
  opacity: 1;
}

.title-hover {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.descriptions {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgb(255 255 255);
  width: 100%;
  height: 100%;
  transition: all 0.7s ease-in-out;
  padding: 20px;
  box-sizing: border-box;
  clip-path: circle(0% at 100% 100%);

  p {
    margin-bottom: 0.5rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1.2rem;
    overflow: hidden;
  }
}
.card-innovation:hover .descriptions {
  left: 0px;
  transition: all 0.5s ease-in-out;
  clip-path: circle(75%);
  border-radius: 15px;
}

.card-innovation:hover {
  transition: all 0.5s cubic-bezier(0.8, 0.5, 0.2, 1.4);
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
  transform: scale(0.97);
}

.MuiSelect-select.MuiSelect-select {
  padding-left: 2rem;
}

.custom-btn {
  width: 130px;
  height: 40px;
  border-radius: 5px;
  padding: 7px 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  // box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
  //   7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  outline: none;
}

/* 14 */
.btn-14 {
  background: #836058;
  border: none;
  z-index: 1;
}
.btn-14:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 5px;
  background-color: #836058;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5) 7px 7px 20px 0px
      rgba(0, 0, 0, 0.1),
    4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}
.btn-14:hover {
  color: #000;
}
.btn-14:hover:after {
  top: auto;
  bottom: 0;
  height: 100%;
}
.btn-14:active {
  top: 2px;
}

.icon-socail {
  margin: 0 5px;
}

.body-innovation {
  // margin: 10px 20px 0px 20px;
  background-color: #fff;
  padding: 2rem;
  padding-right: 3rem;
  border-radius: 10px;
}

.search-innovation {
  background-color: rgb(255 255 255);
  margin-bottom: 2rem;
  border-radius: 15px;
}

.bg-innovation-all {
  padding: 2rem;
}

@media (max-width: 769px) {
  .body-innovation {
    margin-top: 5.2rem;
    margin-bottom: 1rem;
    border-radius: 0px;
  }
}
@media (max-width: 426px) {
  .body-innovation {
    margin: 0;
    margin-bottom: 1rem;
    /* margin-top: 2rem; */
    padding: 15px;
    padding-left: 25px;
    padding-top: 35px;
    border-radius: 0;
  }

  .div-innovation-all {
    margin-top: -30px;
    margin-bottom: -10px;
  }
  .bg-innovation-all {
    padding: 1.5rem;
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

// .MuiButton-containedPrimary{
//   background-color: rgb(239, 125, 5);
// }
// .card-innovation:hover img {
//   transition: all 0.5s cubic-bezier(0.8, 0.5, 0.2, 1.4);
//   // transform: scale(1.6) rotate(20deg);
//   // filter: blur(3px);
// }
