@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&display=swap");

.timeline-researcher {
  .timelinebox {
    --color: rgba(30, 30, 30);
    --bgColor: rgba(245, 245, 245);
    min-height: 100vh;
    display: grid;
    align-content: center;
    gap: 2rem;
    padding: 2rem;
    font-family: "Poppins", sans-serif;
    color: rgba(30, 30, 30);
    background: rgba(245, 245, 245);
  }

  ul {
    --col-gap: 2rem;
    --row-gap: 2rem;
    --line-w: 0.25rem;
    display: grid;
    grid-template-columns: 0.25rem 1fr;
    grid-auto-columns: max-content;
    column-gap: 2rem;
    list-style: none;
    width: min(60rem, 90%);
    margin-inline: auto;
  }

  /* line */
  ul::before {
    content: "";
    grid-column: 1;
    grid-row: 1 / span 20;
    background: #f4a734;
    border-radius: calc(0.25rem / 2);
  }

  /* columns*/

  /* row gaps */
  ul li:not(:last-child) {
    margin-bottom: 2rem;
  }

  /* card */
  ul li {
    grid-column: 2;
    --inlineP: 1.5rem;
    margin-inline: 1.5rem;
    grid-row: span 2;
    display: grid;
    grid-template-rows: min-content min-content min-content;
    background-color: #f5f5f5;
    box-shadow: 3px 4px 1px 2px #e1e1e1;
    margin-top: 2rem;
    padding-bottom: 2rem;
  }

  /* date */
  ul li .date {
    --dateH: 3rem;
    height: 3rem;
    margin-inline: calc(1.5rem * -1);

    text-align: center;
    background-color: var(--accent-color);

    color: white;
    font-size: 1.25rem;
    font-weight: 700;

    display: grid;
    place-content: center;
    position: relative;

    border-radius: calc(3rem / 2) 0 0 calc(3rem / 2);
  }

  /* date flap */
  ul li .date::before {
    content: "";
    width: 1.5rem;
    aspect-ratio: 1;
    background: var(--accent-color);
    background-image: linear-gradient(rgba(0, 0, 0, 0.2) 100%, transparent);
    position: absolute;
    top: 100%;

    clip-path: polygon(0 0, 100% 0, 0 100%);
    right: 0;
  }

  /* circle */
  ul li .date::after {
    content: "";
    position: absolute;
    width: 2rem;
    aspect-ratio: 1;
    background: rgba(245, 245, 245);
    border: 0.3rem solid #f4a734;
    border-radius: 50%;
    top: 50%;

    transform: translate(50%, -50%);
    right: calc(100% + 2rem + 0.25rem / 2);
  }

  /* title descr */
  ul li .title,
  ul li .descr {
    background: rgba(245, 245, 245);
    position: relative;
    padding-inline: 0.5rem;
    text-align: left;
    padding: 1rem 1.5rem 0 1.5rem !important;
  }
  ul li .title {
    overflow: hidden;
    padding-block-start: 1.5rem;
    padding-block-end: 1rem;
    font-weight: 500;
   
  }
  ul li .descr {
    padding-block-end: 1.5rem;
    font-weight: 300;
    padding-top: 0 !important;
  }

  @media (min-width: 40rem) {
    ul {
      grid-template-columns: 1fr 0.25rem 1fr;
    }
    ul::before {
      grid-column: 2;
    }
    ul li:nth-child(odd) {
      grid-column: 1;
    }
    ul li:nth-child(even) {
      grid-column: 3;
    }

    /* start second card */
    ul li:nth-child(2) {
      grid-row: 2/4;
    }

    ul li:nth-child(odd) .date::before {
      clip-path: polygon(0 0, 100% 0, 100% 100%);
      left: 0;
    }

    ul li:nth-child(odd) .date::after {
      transform: translate(-50%, -50%);
      left: calc(100% + 2rem + 0.25rem / 2);
    }
    ul li:nth-child(odd) .date {
      border-radius: 0 calc(3rem / 2) calc(3rem / 2) 0;
    }
  }
}
