.demo:empty {
    text-align: center;
    margin: auto;
    margin-top: 5rem;
    width: 60%;
    height: 200px; /* change height to see repeat-y behavior */
  
    background-image: radial-gradient(
        circle 50px at 50px 50px,
        lightgray 99%,
        transparent 0
      ),
      linear-gradient(
        100deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 80%
      ),
      linear-gradient(lightgray 20px, transparent 0),
      linear-gradient(lightgray 20px, transparent 0),
      linear-gradient(lightgray 20px, transparent 0),
      linear-gradient(lightgray 20px, transparent 0);
  
    background-repeat: repeat-y;

    background-size: 100px 200px, /* circle */ 50px 200px,
      /* highlight */ 600px 200px, 200px 200px, 430px 200px, 250px 200px;
  
    background-position: 0 0, /* circle */ 0 0, /* highlight */ 120px 0,
      120px 40px, 120px 80px, 120px 120px;
  
    animation: shine 1s infinite;
  }
  
  @keyframes shine {
    to {
      background-position: 0 0, 100% 0, /* move highlight to right */ 120px 0,
        120px 40px, 120px 80px, 120px 120px;
    }
  }
  
  // Loading...............
  /* Animation */
  @keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }
  
  /* Variables */
  $loader-size: 100px;
  
  /* Loading Icon */
  .loading {
    width: $loader-size;
    height: $loader-size;
  
    &__ring {
      position: absolute;
      width: $loader-size;
      height: $loader-size;
  
      &:first-child {
        transform: skew(30deg, 20deg);
      }
  
      &:last-child {
        transform: skew(-30deg, -20deg) scale(-1, 1);
  
        svg {
          animation-delay: -0.5s;
        }
      }
  
      svg {
        animation: rotate 1s linear infinite;
        fill: rgba(0, 0, 0, 0.2);
      }
    }
  }