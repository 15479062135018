.div-detail {
  background-color: #d5cbcb;
  padding: 1rem;
  margin-bottom: 0px;
  border-radius: 10px;
}

.arrow-down {
  color: #5d1809;
}
